<template>
  <!--  -->
  <div class="articlePage">
    <div class="content">
      <V-ArticleList :newarticlelist="newarticlelist"></V-ArticleList>
    </div>
    <div class="page-nav">
      <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="hidePageNav"
        :total="totlePage"
        :page-size="pageSize"
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="currentPage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { showTimeFormat } from "@/js/common/index.js";
import ArticleList from "@/components/one-article/type-two/index.vue";
import { DELETE, QUERY } from "@/services/dao.js";
export default {
  data() {
    return {
      hidePageNav: true,
      pageNum: 0,
      pageSize: 12,
      totlePage: 0,
      orderActive: 1,
      hotWords: [],
      newarticlelist: []
    };
  },
  created: function() {
    this.init();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        this.init(); //重新加载数据
      }
    }
  },
  methods: {
    async init() {
      this.id = this.$route.query.id;
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: " +
          this.pageSize +
          ", offset: " +
          this.pageSize * this.pageNum +
          ",  where: {userid: {_eq: " +
          this.id +
          "}}) { imge   id    createTime    content  coverImg       commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference        title     hotNum  }  Article_aggregate ( where: {userid: {_eq: " +
          this.id +
          "}}){    aggregate {      count    }  }"
      );
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: showTimeFormat(info.data.Article[i].createTime),
          collectNum: info.data.Article[i].collectNum,
          userId: 2,
          commentNum: info.data.Article[i].commentNum,
          image:
              info.data.Article[i].coverImg.indexOf("https://") != -1 ||
            info.data.Article[i].coverImg.indexOf("http://") != -1  
              ? info.data.Article[i].coverImg
              : this.api.LoginURL.concat(info.data.Article[i].coverImg) ,
          // image: this.api.LoginURL.concat(info.data.Article[i].coverImg),
          id: info.data.Article[i].id,
          hotNum: info.data.Article[i].hotNum,
          praiseNum: info.data.Article[i].praiseNum,
          keyword: info.data.Article[i].keyword,
          imge: this.api.LoginURL.concat(info.data.Article[i].imge)//头像
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;
    },
    //前一页
    async prevPage(val) {
      this.pageNum = val - 1;
      this.id = this.$route.query.id;
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: " +
          this.pageSize +
          ", offset: " +
          this.pageSize * this.pageNum +
          ",  where: {userid: {_eq: " +
          this.id +
          "}}) { imge   id    createTime    content        commentNum    collectNum coverImg   browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference        title     hotNum  }  Article_aggregate ( where: {userid: {_eq: " +
          this.id +
          "}}){    aggregate {      count    }  }"
      );
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: showTimeFormat(info.data.Article[i].createTime),
          collect: info.data.Article[i].collectNum,
          userId: 2,
          comment: info.data.Article[i].commentNum,
          image: info.data.Article[i].coverImg,
          id: info.data.Article[i].id,
          keyword: info.data.Article[i].keyword,
          imge: info.data.Article[i].imge
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;
      console.log("prevPage" + val);
    },
    //下一页
    async nextPage(val) {
      this.pageNum = val - 1;
      this.id = this.$route.query.id;
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: " +
          this.pageSize +
          ", offset: " +
          this.pageSize * this.pageNum +
          ",  where: {userid: {_eq: " +
          this.id +
          "}}) { imge   id  coverImg  createTime    content        commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference        title     hotNum  }  Article_aggregate ( where: {userid: {_eq: " +
          this.id +
          "}}){    aggregate {      count    }  }"
      );
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: showTimeFormat(info.data.Article[i].createTime),
          collectNum: info.data.Article[i].collectNum,
          userId: 2,
          commentNum: info.data.Article[i].commentNum,
          image: info.data.Article[i].coverImg,
          id: info.data.Article[i].id,
          hotNum: info.data.Article[i].hotNum,
          praiseNum: info.data.Article[i].praiseNum,
          keyword: info.data.Article[i].keyword,
          imge: info.data.Article[i].imge
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;

      console.log("nextPage" + val);
    },
    //当前页
    async currentPage(val) {
      this.pageNum = val - 1;
      this.id = this.$route.query.id;
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: " +
          this.pageSize +
          ", offset: " +
          this.pageSize * this.pageNum +
          ",  where: {userid: {_eq: " +
          this.id +
          "}}) { imge   id    createTime    content   coverImg     commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference        title     hotNum  }  Article_aggregate ( where: {userid: {_eq: " +
          this.id +
          "}}){    aggregate {      count    }  }"
      );
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: showTimeFormat(info.data.Article[i].createTime),
          collect: info.data.Article[i].collectNum,
          userId: 2,
          comment: info.data.Article[i].commentNum,
          image: info.data.Article[i].coverImg,
          id: info.data.Article[i].id,
          keyword: info.data.Article[i].keyword,
          imge: info.data.Article[i].imge
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;

      console.log("currentPage" + val);
    }
  },
  components: {
    "V-ArticleList": ArticleList
  }
};
</script>

<style>
/* 分页按钮样式 */
.page-nav .el-pagination button,
.el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 32px;
  height: 32px;
  border: 2px solid rgba(155, 170, 182, 1);
  border-radius: 50% !important;
  background-color: rgba(237, 237, 237, 0);
  color: #606266;
}
.page-nav .el-pagination {
  width: 788px;
  height: 48px;
  background: rgba(237, 237, 237, 1);
  position: relative;
  padding-top: 20px;
  text-align: center;
}
.page-nav .el-pagination .btn-prev {
  position: absolute;
  left: 0px;
}
.page-nav .el-pagination .btn-next {
  position: absolute;
  right: 0px;
}
.page-nav .el-pagination button i {
  width: 100%;
  height: 100%;
}
.page-nav .el-pagination.is-background .el-pager li {
  width: 32px;
  height: 32px;
  background-color: rgba(237, 237, 237, 0);
  border: 2px solid rgba(237, 237, 237, 0);
  border-radius: 50% !important;
}
.page-nav .el-pagination.is-background .el-pager .number:not(.active):hover {
  width: 32px;
  height: 32px;
  border: 2px solid rgb(238, 91, 46);
  border-radius: 50% !important;
  background-color: white;
  color: rgb(238, 91, 46) !important;
}
.page-nav .el-icon-arrow-left:before,
.el-icon-arrow-right:before {
  content: " ";
}
.page-nav .el-pagination .btn-prev .el-icon {
  background-image: url(~@/assets/icon/icon.png);
  background-position-x: 715px;
  background-position-y: -604px;
}
.page-nav .el-pagination .btn-next .el-icon {
  background-image: url(~@/assets/icon/icon.png);
  background-position-x: 765px;
  background-position-y: -604px;
}
.page-nav .el-pagination button:hover {
  width: 32px;
  height: 32px;
  border: 2px solid rgb(238, 91, 46);
  border-radius: 50% !important;
  background-color: rgba(237, 237, 237, 0);
  color: #606266;
}
.page-nav .el-pagination .btn-next:hover .el-icon {
  background-image: url(~@/assets/icon/icon.png);
  background-position-x: 671px;
  background-position-y: -564px;
}
.page-nav .el-pagination .btn-prev:hover .el-icon {
  background-image: url(~@/assets/icon/icon.png);
  background-position-x: 667px;
  background-position-y: -603px;
}
</style>
