<template>
  <div class="article-list">
    <div class="one-article" v-for="onearticle in newarticlelist" :key="onearticle.id">
      <div class="article-img">
        <img
          :src="onearticle.image"
          @click="goToArticleDetail(onearticle.id, onearticle.hotNum)"
          style="width:100%;height:100%"
          alt
        />
      </div>
      <!-- 
      -->
      <div class="article-container">
        <div class="article-title">
          <span @click="goToArticleDetail(onearticle.id, onearticle.hotNum)">{{ onearticle.title }}</span>
        </div>
        <div class="article-intro" @click="goToArticleDetail(onearticle.id, onearticle.hotNum)">
          <span>{{ onearticle.intro }}</span>
        </div>
        <div class="article-one-info">
          <div class="intro-first">
            <img
              class="head-icon"
              :src="onearticle.imge"
              @click="goToUserDetail(onearticle.userId)"
            />
            <span
              class="author-name"
              @click="goToUserDetail(onearticle.userId)"
            >{{ onearticle.name }}</span>
            <span class="publish-tihme">{{ onearticle.time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateHotnumFn, integralAddOne } from "@/js/common/index.js";
export default {
  props: {
    newarticlelist: {
      type: Array,
      required: true
    }
  },
  methods: {
    //打开文章详情
    goToArticleDetail(id, hotNum) {
      console.log("type-two: ");
      console.log(this.newarticlelist);
      updateHotnumFn(id, hotNum); ///添加浏览数
      let user = this.$store.state.userinfo.id;
      if (user) {
        integralAddOne(user); //添加积分
      }
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id
        }
      });
    },
    //打开用户详情
    goToUserDetail(userId) {
      this.$router.push({
        path: "/author",
        query: {
          id: userId
        }
      });
    }
  }
};
</script>

<style scoped>
.article-img:hover,
.article-title > span:hover,
.article-intro > span:hover,
.head-icon:hover,
.author-name:hover {
  cursor: pointer;
}
.article-list {
  padding: 0 22px;
  text-align: left;
  background: white;
}
.article-list .one-article {
  background: white;
  height: 150px;
  padding: 20px 0;
}

.article-list > .one-article {
  border-bottom: 1px dashed rgba(111, 111, 111, 1);
}
.article-container,
.article-img {
  display: inline-block;
  height: 100%;
  position: relative;
}
.article-img {
  width: 33%;
}
.article-container {
  width: 65%;
  vertical-align: top;
  margin-left: 2%;
  /* background: brown; */
  /* padding-left: 3%; */
}
.article-title > span {
  color: #5e6b73;
  font-size: 18px;
  /* font-weight:bolder; */
  line-height: 24px;
}
.article-intro {
  color: #5e6b73;
  font-size: 14px;
  font-weight: lighter;
}
.article-one-info {
  position: absolute;
  width: 96%;
  height: 30px;
  bottom: 0px;
}
.article-other-info {
  position: absolute;
  bottom: 0px;
  width: 96%;
}
.intro-first {
  display: inline-block;
  position: absolute;
  left: 0px;
  bottom: 0;
}
.intro-first > *,
.intro-second > * {
  vertical-align: middle;
}
.head-icon {
  height: 30px;
  width: 30px;
  /* background-image: url(../../../assets/img/icon-person.png) ; */
  display: inline-block;
  border-radius: 50%;
}

.author-name,
.collect-num,
.comment-num,
.publish-tihme {
  color: #a5a7b3;
}
.author-name {
  margin-left: 5px;
}
.publish-tihme,
.comment-icon {
  margin-left: 80px;
}
.intro-second {
  display: inline-block;
  position: absolute;
  right: 0px;
  bottom: 0;
}
.intro-second .keyword {
  border: 1px solid rgba(181, 181, 181, 1);
  border-radius: 13px;

  font-size: 12px;
  padding: 3px;
}
.intro-second .keyword:not(:first-of-type) {
  margin-left: 5px;
}

.keyword-icon {
  height: 18px;
  width: 18px;
  background-image: url(../../../assets/icon/icon.png);
  background-position-x: 313px;
  background-position-y: -39px;
  display: inline-block;
}
.collect-icon {
  height: 14px;
  width: 14px;
  background-image: url(../../../assets/img/icon-collect.png);
  display: inline-block;
}

.article-other-info .clo-info {
  width: 20%;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.article-other-info .clo-info * {
  vertical-align: middle;
}
.article-other-info .clo-info span {
  font-size: 14px;
}
.article-other-info span,
.article-one-info span {
  color: #a5a7b3;
}
.article-one-info .intro-second span:hover {
  cursor: pointer;
  background-color: #fd6e05;
  color: white;
  border-color: white;
}
/* 图标icon */
.hot-icon {
  height: 18px;
  width: 18px;
  background-image: url(../../../assets/icon/icon.png);
  background-position-x: 460px;
  background-position-y: -41px;
  display: inline-block;
}
.attention-icon {
  height: 18px;
  width: 18px;
  background-image: url(../../../assets/icon/icon.png);
  background-position-x: 433px;
  background-position-y: -43px;
  display: inline-block;
}
.praise-icon {
  height: 18px;
  width: 18px;
  background-image: url(../../../assets/icon/icon.png);
  background-position-x: 405px;
  background-position-y: -41px;
  display: inline-block;
}
.comment-icon {
  height: 18px;
  width: 18px;
  background-image: url(../../../assets/icon/icon.png);
  background-position-x: 356px;
  background-position-y: -40px;
  display: inline-block;
}
</style>
